<template>
  <BaseInput
    :label="label"
    :tooltip="tooltip"
    :validation="validation"
    :row-style="rowStyle"
    :no-border="noBorder"
  >
    <template #input="{ uid }">
      <!-- Native date picker -->
      <div v-if="useNativePicker">
        <input
          v-if="!value && !nativeDateInputFocused"
          :id="uid"
          ref="datePlaceholderInput"
          :key="'datePlaceholderInput'"
          class="eb-input-group-input flex-grow-1"
          type="text"
          :placeholder="computedFormat"
          @focus="focusNativeInput"
        />
        <input
          v-else
          :id="uid"
          ref="nativeDateInput"
          :key="'nativeDateInput'"
          class="eb-input-group-input flex-grow-1"
          :value="value"
          :type="
            type === 'date'
              ? 'date'
              : type === 'datetime'
              ? 'datetime-local'
              : null
          "
          :autocomplete="$attrs.autocomplete"
          :placeholder="computedFormat"
          v-on="listeners"
          @focus="nativeDateInputFocused = true"
          @blur="nativeDateInputFocused = false"
        />
      </div>
      <!-- Guided date select -->
      <div v-else class="flex-grow-1 w-100">
        <DatePicker
          ref="datePicker"
          :lang="$i18n.locale.toLowerCase()"
          :input-attr="{ id: uid }"
          class="eb-datepicker"
          input-class="eb-input-group-input"
          popup-class="eb-datepicker-popup"
          :value-type="computedValueType"
          range-separator=" - "
          :value="value"
          :placeholder="placeholder || computedFormat || label"
          v-bind="$attrs"
          :type="type"
          :format="computedFormat"
          :title-format="$t('common.datetime.formats.L')"
          :time-title-format="$t('common.datetime.formats.LT')"
          :range="range"
          :get-classes="getClasses"
          :hour-options="useCommonHours ? commonHours : undefined"
          :minute-step="minuteStep"
          :append-to-body="appendToBody"
          v-on="listeners"
        >
          <template #icon-calendar>
            <fa class="eb-text-tertiary fa-fw" :icon="['fad', 'calendar']" />
          </template>
          <template #icon-clear>
            <fa class="eb-text-tertiary fa-fw" :icon="['far', 'times']" />
          </template>
          <!-- Shortcuts -->
          <template v-if="shortcuts" #header>
            <HorizontalScroll
              ref="horizontalScroller"
              class="eb-datepicker-shortcuts"
            >
              <ul>
                <li v-for="(shortcut, index) in shortcuts" :key="index">
                  <a href="#" @click.prevent="handleShortcut(shortcut)">
                    {{ shortcut.text }}
                  </a>
                </li>
              </ul>
            </HorizontalScroll>
          </template>
        </DatePicker>
      </div>
    </template>
    <!-- Pass all slots through -->
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </BaseInput>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import BaseInput from './BaseInput'
import HorizontalScroll from '../utils/HorizontalScroll'
import dayjs from 'dayjs'

/**
 * @link https://github.com/mengxiong10/vue2-datepicker
 */
export default {
  name: 'AnnyDatePicker',
  components: {
    HorizontalScroll,
    BaseInput,
    DatePicker,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * date|datetime|year|month|time|week
     */
    type: {
      type: String,
      default: 'date',
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Array, Object, String],
      default: undefined,
    },
    validation: {
      type: [Object, Array],
      required: false,
      default: undefined,
    },
    format: {
      type: String,
      required: false,
      default: undefined,
    },
    range: {
      type: Boolean,
      default: false,
    },
    valueType: {
      type: String,
      default: undefined,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    rowStyle: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    /**
     * format: { text: string, onClick: () => Date }
     * @link https://github.com/mengxiong10/vue2-datepicker
     */
    shortcuts: {
      type: Array,
      required: false,
      default: undefined,
    },
    /**
     * Only show common hour options
     */
    useCommonHours: {
      type: Boolean,
      default: true,
    },
    minuteStep: {
      type: Number,
      default: 5,
    },
    /**
     * Show native datepicker
     */
    nativePicker: {
      type: Boolean,
      default: false,
    },
    nativePickerOnMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // common hours 06:00 - 22:00
      commonHours: Array.from({ length: 17 }).map((_, i) => i + 6),
      nativeDateInputFocused: false,
    }
  },
  computed: {
    computedValueType() {
      if (this.valueType) return this.valueType

      switch (this.type) {
        case 'date':
          return 'YYYY-MM-DD'
        case 'datetime':
          return 'YYYY-MM-DDTHH:mm:ssZ'
        case 'time':
          return 'HH:mm:ss'
        case 'year':
          return 'YYYY'
        case 'month':
          return 'MM'
        case 'week':
          return 'w'
        default:
          return 'YYYY-MM-DD'
      }
    },
    computedFormat() {
      if (this.format) {
        return this.format
      }

      switch (this.type) {
        case 'date':
          return this.$i18n.t('common.datetime.formats.L')
        case 'datetime':
          return this.$i18n.t('common.datetime.formats.LLL')
        case 'time':
          return this.$i18n.t('common.datetime.formats.LT')
        case 'year':
          return 'YYYY'
        case 'month':
          return 'MM'
        case 'week':
          return 'w'
        default:
          return this.$i18n.t('common.datetime.formats.L')
      }
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.handleInput,
      }
    },
    /**
     * Check if browser supports native date input
     */
    isNativePickerSupported() {
      if (process.server) return false
      const input = document.createElement('input')
      if (this.type === 'date') {
        input.setAttribute('type', 'date')
      } else if (this.type === 'datetime') {
        input.setAttribute('type', 'datetime-local')
      } else {
        return false
      }
      const value = 'a'
      input.setAttribute('value', value)
      return input.value !== value
    },
    /**
     * Compute if native picker shall be used
     */
    useNativePicker() {
      if (this.$store.hasModule('ux')) {
        if (this.nativePickerOnMobile) {
          let bowser = this.$store.getters['ux/getField']('bowser')
          if (bowser?.isPlatform('mobile') || bowser?.isPlatform('tablet')) {
            return this.isNativePickerSupported
          }
        }
      }
      return this.isNativePickerSupported && this.nativePicker
    },
  },
  watch: {
    shortcuts() {
      if (this.$refs.horizontalScroller) {
        this.$nextTick(() => this.$refs.horizontalScroller.$emit('update'))
      }
    },
  },
  methods: {
    handleInput(event) {
      if (this.useNativePicker) {
        this.$emit('input', event.target.value)
      } else {
        this.$emit('input', event)
      }
      this.setDirty()
    },
    setDirty() {
      this.$nextTick(function () {
        if (this.validation) this.validation.$touch()
      })
    },
    handleShortcut(shortcut) {
      if (typeof shortcut.onClick === 'function') {
        let res = shortcut.onClick()
        this.$emit('input', res)
      }
    },
    /**
     * Workaround for iOS issue
     * inputs with type date or datetime-local are shrunken or hidden without a value.
     * ref: https://github.com/twbs/bootstrap/issues/23307
     */
    async focusNativeInput() {
      this.nativeDateInputFocused = true
      await this.$nextTick()
      this.$refs.nativeDateInput?.focus()
    },
    /**
     * Get classes for specific cells
     * @link https://github.com/mengxiong10/vue2-datepicker/issues/464#issuecomment-623941548
     * @param cellDate (Date object for cell)
     * @param currentDates (array of selected dates - 1 or 2)
     * @return {string}
     */
    getClasses(cellDate, currentDates) {
      // mark active date range
      if (currentDates.length === 2) {
        let cellDayjs = dayjs(cellDate)
        let sameAsStart = cellDayjs.isSame(currentDates[0], 'day')
        let sameAsEnd = cellDayjs.isSame(currentDates[1], 'day')
        let isInRange =
          cellDayjs.isAfter(currentDates[0], 'day') &&
          cellDayjs.isBefore(currentDates[1], 'day')
        if (sameAsStart && sameAsEnd) {
          return 'active-start active-end'
        } else if (sameAsStart) {
          return 'active-start'
        } else if (sameAsEnd) {
          return 'active-end'
        } else if (isInRange) {
          return 'active in-range'
        }
      } else if (currentDates.length === 1) {
        let cellDayjs = dayjs(cellDate)
        if (cellDayjs.isSame(currentDates[0], 'day')) {
          return 'active-start active-end'
        }
      }
      return ''
    },
  },
}
</script>
