var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eb-input-group",class:{
    error: _vm.error || (_vm.validation && _vm.validation.$error),
    'eb-input-row': _vm.rowStyle && !_vm.embedded,
    focused: _vm.focused,
    'no-border': _vm.noBorder,
  }},[(_vm.label && !_vm.hideLabel && !_vm.embedded)?_c('label',{staticClass:"eb-input-group-label",attrs:{"for":_vm.uid}},[_c('span',[_vm._t("default",function(){return [_vm._v("\n        "+_vm._s(_vm.label)+"\n        "),(_vm.validation && _vm.validation.hasOwnProperty('required'))?_c('span',[_vm._v("*")]):_vm._e(),_vm._v(" "),(_vm.tooltip)?_c('Info',{attrs:{"tooltip":_vm.tooltip,"icon-style":"far","primary":false}}):_vm._e()]})],2),_vm._v(" "),_c('span',{staticClass:"flex-grow-1"}),_vm._v(" "),_c('span',[_vm._t("label-right"),_vm._v(" "),(_vm.showUsedLength)?_c('small',{staticClass:"eb-input-length",class:{ 'eb-danger-color': _vm.maxLength && _vm.usedLength > _vm.maxLength }},[_vm._v("\n        "+_vm._s(_vm.usedLength)+_vm._s(_vm.maxLength ? '/' + _vm.maxLength : '')+"\n        "+_vm._s(_vm.$t('common.components.base.input.characters'))+"\n      ")]):_vm._e()],2)]):_vm._e(),_vm._v(" "),_c('div',{class:{ 'eb-input-group-input-container': !_vm.embedded }},[_c('div',{staticClass:"position-relative d-flex align-items-center"},[_vm._t("input-prefix"),_vm._v(" "),_vm._t("input",function(){return [_c('input',_vm._g(_vm._b({ref:"input",staticClass:"flex-grow-1",class:{
            'text-end': _vm.textAlign === 'right',
            'text-center': _vm.textAlign === 'center',
            'large-input': _vm.largeInput,
            'eb-input-embedded': _vm.embedded,
            'eb-input-group-input': !_vm.embedded,
            ..._vm.inputClass,
            'hide-arrows': _vm.hideArrows && _vm.inputType === 'number',
          },style:(_vm.inputStyle),attrs:{"id":_vm.uid,"type":_vm.inputType,"placeholder":_vm.placeholder || _vm.label,"inputmode":_vm.inputmode,"autocomplete":_vm.autocomplete,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"autocomplete":_vm.handleAutocomplete,"click":function($event){return _vm.$refs.input.focus()}}},'input',_vm.$attrs,false),_vm.listeners))]},{"uid":_vm.uid}),_vm._v(" "),_vm._t("input-suffix"),_vm._v(" "),_vm._t("input-overlay",null,{"uid":_vm.uid,"validation":_vm.validation}),_vm._v(" "),(_vm.clearable && _vm.value)?_c('div',{staticClass:"eb-input-btn-clear"},[_c('button',{staticClass:"eb-btn-clear",on:{"click":function($event){return _vm.$emit('input', '')}}},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":['far', 'times']}})],1)]):_vm._e()],2),_vm._v(" "),_vm._t("hint",function(){return [(_vm.hint)?_c('small',[_vm._v(_vm._s(_vm.hint))]):_vm._e()]}),_vm._v(" "),_vm._t("error",function(){return [(_vm.error && _vm.errorText)?_c('small',{staticClass:"eb-danger-color"},[_vm._v("\n        "+_vm._s(_vm.errorText)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.validation && _vm.validation.$anyError)?[_c('vuelidate-error',{attrs:{"validation":_vm.validation,"validation-messages":_vm.validationMessages,"label":_vm.label}})]:_vm._e()]})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }