<template>
  <div
    class="eb-input-group"
    :class="{
      error: error || (validation && validation.$error),
      'eb-input-row': rowStyle && !embedded,
      focused: focused,
      'no-border': noBorder,
    }"
  >
    <!-- Label -->
    <label
      v-if="label && !hideLabel && !embedded"
      class="eb-input-group-label"
      :for="uid"
    >
      <span>
        <slot>
          {{ label }}
          <span v-if="validation && validation.hasOwnProperty('required')"
            >*</span
          >
          <!-- Info tooltip -->
          <Info
            v-if="tooltip"
            :tooltip="tooltip"
            icon-style="far"
            :primary="false"
          />
        </slot>
      </span>
      <!-- Spacer -->
      <span class="flex-grow-1"></span>
      <!-- Used characters and max length (floating) -->
      <span>
        <slot name="label-right" />
        <small
          v-if="showUsedLength"
          class="eb-input-length"
          :class="{ 'eb-danger-color': maxLength && usedLength > maxLength }"
        >
          {{ usedLength }}{{ maxLength ? '/' + maxLength : '' }}
          {{ $t('common.components.base.input.characters') }}
        </small>
      </span>
    </label>

    <!-- Input -->
    <div :class="{ 'eb-input-group-input-container': !embedded }">
      <div class="position-relative d-flex align-items-center">
        <slot name="input-prefix" />
        <slot name="input" :uid="uid">
          <input
            :id="uid"
            ref="input"
            :type="inputType"
            class="flex-grow-1"
            :class="{
              'text-end': textAlign === 'right',
              'text-center': textAlign === 'center',
              'large-input': largeInput,
              'eb-input-embedded': embedded,
              'eb-input-group-input': !embedded,
              ...inputClass,
              'hide-arrows': hideArrows && inputType === 'number',
            }"
            :style="inputStyle"
            :placeholder="placeholder || label"
            :value="value"
            :inputmode="inputmode"
            v-bind="$attrs"
            :autocomplete="autocomplete"
            :disabled="disabled"
            v-on="listeners"
            @autocomplete="handleAutocomplete"
            @click="$refs.input.focus()"
          />
        </slot>
        <slot name="input-suffix" />
        <slot name="input-overlay" :uid="uid" :validation="validation" />
        <!-- Clear button -->
        <div v-if="clearable && value" class="eb-input-btn-clear">
          <button class="eb-btn-clear" @click="$emit('input', '')">
            <fa :icon="['far', 'times']" class="fa-fw" />
          </button>
        </div>
      </div>

      <!-- Hint for filling the input -->
      <slot name="hint">
        <small v-if="hint">{{ hint }}</small>
      </slot>

      <!-- Display Validation errors -->
      <slot name="error">
        <small v-if="error && errorText" class="eb-danger-color">
          {{ errorText }}
        </small>
        <!-- Vuelidate errors -->
        <template v-if="validation && validation.$anyError">
          <vuelidate-error
            :validation="validation"
            :validation-messages="validationMessages"
            :label="label"
          />
        </template>
      </slot>
    </div>
  </div>
</template>

<script>
import Info from '../utils/Info'
import VuelidateError from '@/shared/components/utils/VuelidateError'
export default {
  name: 'BaseInput',
  components: { VuelidateError, Info },
  inheritAttrs: false,
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * Input Value
     */
    value: {
      type: [Number, String, Boolean],
      default: '',
    },
    /**
     * Input Label
     */
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * Input type
     */
    inputType: {
      type: String,
      default: 'text',
    },
    /**
     * Input placeholder
     */
    placeholder: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    /**
     * Input error
     * not needed when validation is set
     */
    error: {
      type: Boolean,
      default: false,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * Small footnote
     */
    hint: {
      type: String,
      required: false,
      default: undefined,
    },
    /**
     * Vuelidate validation object
     */
    validation: {
      type: Object,
      required: false,
      default: undefined,
    },
    /**
     * Display used characters at top right corner
     */
    showUsedLength: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Change text alignment
     */
    textAlign: {
      type: String,
      default: 'left',
    },
    /**
     * Custom class for input element
     */
    inputClass: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Custom style for input element
     */
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Info tooltip text
     */
    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
    rowStyle: {
      type: Boolean,
      default: false,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    largeInput: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
    inputmode: {
      type: String,
      required: false,
      default: undefined,
    },
    inputId: {
      type: String,
      required: false,
      default: undefined,
    },
    trimOnBlur: {
      type: Boolean,
      default: false,
    },
    validationMessages: {
      type: Object,
      required: false,
      default: null,
    },
    hideArrows: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      uid: null,
      lastValue: this.value,
      emitting: false,
    }
  },
  computed: {
    /**
     * Map listeners to input
     * Set changed on blur
     * @returns {{input: (function(*): this), blur: blur}}
     */
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => {
          this.emitting = true
          this.$emit('input', event.target.value)
        },
        blur: (e) => {
          this.$nextTick(() => {
            if (this.trimOnBlur) {
              let trimmed = String(this.value).trim()
              this.$emit('input', trimmed)
            }
            this.$emit('blur', e)
            this.setChanged()
          })
        },
      }
    },
    /**
     * Check if max length is set
     * @returns {number|boolean}
     */
    maxLength() {
      return this.validation &&
        Object.prototype.hasOwnProperty.call(this.validation, 'maxLength')
        ? this.validation.$params.maxLength.max
        : false
    },
    /**
     * Return used characters
     * @returns {number}
     */
    usedLength() {
      return this.value ? this.value.length : 0
    },
  },
  watch: {
    /**
     * Append unique input id to vuelidate object
     * @param newVal
     */
    validation(newVal) {
      if (!newVal) return
      newVal.$inputId = this.uid
    },
    value(newValue) {
      if (!this.emitting) {
        // handle updated value from outside
        this.lastValue = newValue
      } else {
        this.emitting = false
      }
    },
  },
  mounted() {
    this.uid = this.inputId || 'input_' + this._uid
    if (this.validation) {
      // eslint-disable-next-line vue/no-mutating-props
      this.validation.$inputId = this.uid
    }
  },
  methods: {
    /**
     * Touch validation
     */
    setChanged() {
      // touch validation if value changed
      if (this.validation && this.value !== this.lastValue) {
        this.validation.$touch()
        this.lastValue = this.value
      }
    },
    /**
     * Focus the input
     */
    focus(options = {}) {
      this.$refs.input?.focus(options)
    },
    select() {
      this.$refs.input?.select()
    },
    handleAutocomplete(event) {
      //
    },
  },
}
</script>
<style lang="scss">
</style>
